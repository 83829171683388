import React from "react"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Yes - It most certainly is.</title>
          <link rel="canonical" href="https://iscriticalracetheorymarxist.com" />
        </Helmet>
          <h1>Yes.</h1>
          <div>Last Updated: {new Date().toLocaleString()}</div>
          <br />
          <div>Stack sats: 34nXYimSBcTonThEs7AbM7RoBTBUSTXe6w</div>
          <div>Stack gwei: 0x2fc1a9160Bc399CA8d5601489C871526179e8d9A</div>
        </>
        )
}
